* {
  box-sizing: border-box;
}
body {
  background: #f7f7f7;
}

*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}
*::-webkit-scrollbar-track {
  /* background: rgb(175, 175, 175); */
}

*::-webkit-scrollbar-thumb {
  background-color: #999999;
  /* background-color: #154360; */
  border-radius: 20px;
  /* border: 1px solid red; */
}

.MuiSkeleton-root {
  margin: 8px 0px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

th {
  font-size: 14px !important;
  font-weight: 700 !important;
  border: 1px solid #ddd;
  border-bottom: 2px solid #000 !important;
  padding: 10px 10px 10px 10px !important;
}
td {
  font-size: 14px !important;
  border: 1px solid #ddd;
  padding: 5px 10px !important;
}
tbody tr:hover {
  background: #f9f9f9;
}

/* google map input css */
.location-search-input {
  font-family: "Lato", sans-serif;
  width: 100%;
  height: 40px;
  background: none;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
  /* margin-top: 300px; */
  /* margin-left: 5rem; */
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.input-suggestion {
  font-family: "Lato", sans-serif;
  width: 500px;
  height: 60px;
  background-color: white;
  border-bottom: 1px black dotted;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  /* margin-left: 5rem; */
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}
